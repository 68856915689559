import { modifySession } from "@/services/models/navigation";

const navigation = {
  namespaced: true,
  state: {
    dataNavigation: null,
    rentalNavigation: null,
  },

  getters: {
    dataNavigation: (state) => state.dataNavigation,
    rentalNavigation: (state) => state.rentalNavigation,
  },

  mutations: {
    SET_DATA_NAVIGATION(state, payload) {
      state.dataNavigation = payload;
    },

    SET_RENTAL_NAVIGATION(state, payload) {
      state.rentalNavigation = payload;
    },
  },

  actions: {
    setNavigation({ commit }, data) {
      sessionStorage.setItem("session", JSON.stringify(data));
      commit("SET_DATA_NAVIGATION", data);
    },

    async setRentalNavigation({ commit }, data) {
      const rental_company = JSON.parse(data);
      const modifySessionData = await modifySession(rental_company.id);
      if (
        modifySessionData &&
        modifySessionData.status == 200 &&
        modifySessionData.data.success
      ) {
        commit("SET_RENTAL_NAVIGATION", data);
      }
    },
  },
};

export default navigation;
